<template>
  <div class="dependant-select-table">
    <!--  Header    -->
    <CRow class="m-0 pb-3" v-if="isHeader">
      <CCol col="12" class="d-flex p-0">
        <h3 class="mb-2 mr-1 settings-bread-content">{{ title }}</h3>
        <IconWithTooltip class="mt-2" :text="toolTipText"/>
      </CCol>
    </CRow>
    <!-- Table  Header  -->
    <div class="d-table-header" :class="{'zq-hide': !ready}">
      <ClSearch
        class="mb-2"
        placeholder="tableHeader.search"
        @searchUpdate="searchData"
      />

      <div class="d-flex justify-content-end  mb-3">
        <CButton
          variant="outline"
          color="dark"
          @click="goBack"
          class="mr-2 wizard-action-btn zq--custom-button"
        >
          {{ $t('buttons.cancel') }}
        </CButton>

        <ClResponsiveButton :label="$t('buttons.add')" @handler="save"/>
      </div>
    </div>
    <!--  Table  -->
    <CRow class="mr-0">
      <CCol col="12" class="pr-0">
        <CCard class="zq-card-table">
          <CCardHeader v-if="valid === false">
            <span class="table-error">Select dependencies</span>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              id="memberGroupsTable"
              ref="memberGroupsTable"
              class="zq--table"
              :items="storeEntity"
              :fields="fields"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:column-filter-value="handleColumnFilter"
              @update:sorter-value="handleSortBy"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #metadata="{item}">
                <td>{{ getMetaData(item.metadata) }}</td>
              </template>
              <template #tags="{item}">
                <td>{{ item.tags }}</td>
              </template>

              <!--       Select All        -->
              <template #select-header class="text-center">
                <div class="position-relative zq--table-select-all" v-theme-select-all>
                  <ClCheckbox
                    :checkedProp="toggleFlag"
                    icon="fa-angle-down"
                    @handler="selectAll"
                  />
                </div>
              </template>
              <!--       Select         -->
              <template #select="{ item }">
                <td :data-id="item.id" class="text-center select-td fixed-column-start">
                  <ClCheckbox
                    :valueProp="item.id"
                    :checkedProp="selectedTags.indexOf(item.id) > -1"
                    @handler="checkAchievements"
                  />
                </td>
              </template>
              <template #actions-header>
                <div></div>
              </template>
              <template #actions-filter>
                <div></div>
              </template>

              <template #actions>
                <div></div>
              </template>

            </CDataTable>
            <!--        Table Footer           -->
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="resultCount"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TableFooter from '@/components/table/Footer';
import TableLoader from "@/components/table/Loader";
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';
import ClSearch from '@/shared/components/formComponents/ClSearch';
import {pageConfig} from "@/config";
import {cloneDeep, uniq, startCase} from "lodash";
import {dateFormate} from "@/utils";
import {mapGetters} from "vuex";
import ClCheckbox from "@/shared/components/formComponents/ClCheckbox";

export default {
  name: 'DepSelectEntrantsTable',
  components: {
    IconWithTooltip,
    TableFooter,
    TableLoader,
    ClResponsiveButton,
    ClSearch,
    ClCheckbox
  },
  props: {
    selectedData: Array,
    formData: Object,
    toolTipText: String,
    entityType: String,
    storeType: {
      type: String,
      default: 'achievements'
    },
    title: String,
    isHeader: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      default() {
        return [
          "select",
          "id",
          "created",
          "name",
          "description",
          "category",
          "constraints",
        ]
      }
    }
  },
  data() {
    return {
      valid: null,
      toggleFlag: false,
      columnFilter: false,
      ready: false,
      query: {},
      sortBy: pageConfig.sortBy,
      itemsPerPage: pageConfig.itemsPerPage,
      page: pageConfig.page,
      search: '',
      selectedTags: [],
      tagsData: [],
      tagsFormData: [],
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    storeEntity() {
      return this.$store.getters[`${this.storeType}/${this.storeType}`];
    },
    loading() {
      return this.$store.getters[`${this.storeType}/loading`];
    },
    resultCount() {
      return this.$store.getters[`${this.storeType}/resultCount`];
    },
    pages() {
      return this.$store.getters[`${this.storeType}/pages`];
    },
    message() {
      return this.$store.getters[`${this.storeType}/message`];
    },
    storeAction() {
      return `${this.storeType}/handleGet${startCase(this.storeType)}ByQuery`
    },
    storeQueryAction() {
      return `${this.storeType}/handleGet${startCase(this.storeType)}ByQuery`
    },
    searchableAllFields() {
      return this.$store.getters[`${this.storeType}/searchableAllFields`];
    },
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.queryDB();
      this.ready = true;
      this.tagsFormData = cloneDeep(this.formData);
      this.selectedTags = this.selectedData.map(item => item.id);
      this.tagsData = this.storeEntity.filter(item => this.selectedTags.includes(item.id))
    },
    async queryDB() {
      await this.$store.dispatch(this.storeAction, {
        queryRequest:
          {
            must: [{queryField: 'entityTypes', queryValues: [this.entityType]}],
            multiFields: this.multiFields,
            sortBy: this.sortBy,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
      });
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.$store.dispatch(this.storeAction, {
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    async paginationChange(val) {
      this.page = val;
      await this.queryDB();
    },
    async handleColumnFilter(e) {
      this.multiFields = [];
      let str = "";
      Object.keys(e).forEach((x) => {
        if (e[x] !== ' ' && e[x] !== ' ') {
          str = e[x].replace(/\s+/g, '');
          if(str.length > 0) {
            this.multiFields.push({
              queryFields: [x],
              queryValue: str,
            });
          } else return false;
        }
      });
      await this.queryDB();
    },
    async handleSortBy(e) {
      if (this.skipSort && this.skipSort.includes(e.column)) {
        return;
      }
      const sort = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.sortBy = sort;
      this.page = 1;
      await this.queryDB();
    },
    removeFromDependOn(id) {
      Object.keys(this.tagsFormData.dependantOn || []).map((item) => {
        if (item === 'shouldMatchAtLeast') return;
        return this.tagsFormData.dependantOn[item] = this.tagsFormData.dependantOn[item].filter(item => item !== id)
      });
    },
    checkAchievements({event}) {
      const selectId = event.target.value;
      const index = this.selectedTags.indexOf(selectId);
      const ach = this.storeEntity.find(item => item.id === selectId);
      if (index > -1) {
        this.removeFromDependOn(selectId);
        this.selectedTags.splice(index, 1);
        this.tagsData = this.tagsData.filter(item => item.id !== selectId)
      } else {
        this.selectedTags.push(selectId);
        this.tagsData.push(ach)
      }
    },
    selectAll() {
      const tags = cloneDeep(this.storeEntity);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag) {
        tags.forEach(item => this.selectedTags.push(item.id));
        this.tagsData = tags;
      } else {
        this.selectedTags = [];
        this.tagsData = [];
      }
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    async searchData(val) {
      this.columnFilter = false;
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      this.multiFields = [];
      if (val.trim() !== '') {
        this.multiFields.push({
          queryFields: this.searchableAllFields,
          queryValue: val.trim(),
        });
      }
      await this.queryDB();
    },
    resetSearch() {
      this.searchData(null)
    },
    goBack() {
      this.$emit('toResultTable')
    },
    save() {
      if (this.selectedTags.length) {
        this.$emit('updateSelectData', {
          selectedData: uniq(this.tagsData),
          formData: this.tagsFormData
        });
        this.$emit('toResultTable');
        this.valid = null;
      } else {
        this.valid = false;
      }
    }
  },
}
</script>